/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Result, Typography } from 'antd';
import { LinkText, TopNavButtonHiddenOnMobile } from 'components/MainNav/TopNavComponents';

import messages from './messages';
import styled from 'styles/styled-components';
import Helmet from 'react-helmet';
import translations from 'translations';
import media from 'utils/mediaStyle';

const { Title } = Typography;

const Container = styled.div`
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @media (max-width: 1024px) {
    margin-top: 6px;
  }
`;

const PageContainer = styled(Container)`
  padding: 24px;
  ${media.md`
    padding: 24px;
  `};
`;

const BackToHome = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  & ${TopNavButtonHiddenOnMobile} {
    @media (max-width: 1076px) {
      display: block;
    }
  }
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
        <meta name="description" content={`${translations(messages.header)}`} />
      </Helmet>
      <PageContainer>
        <Result
          status="404"
          title="404"
          subTitle={
            <SectionHeading level={2}>
              <FormattedMessage {...messages.header} />
            </SectionHeading>
          }
          extra={
            <BackToHome>
              <LinkText to="/" rel="nofollow">
                <TopNavButtonHiddenOnMobile>
                  <Button type="primary" size="small">
                    Go to Home
                  </Button>
                </TopNavButtonHiddenOnMobile>
              </LinkText>
            </BackToHome>
          }
        />
      </PageContainer>
    </Fragment>
  );
};

export default NotFound;
